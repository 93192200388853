<template>
  <div>
    <div>
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mb-2'
            >
              <b-form-group
                :label="$t('labels.title')"
                label-for='name'
              >
                <b-form-input
                  id='title'
                  :value='searchFields.title'
                  @input="inputChange($event, 'title')"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mb-2'
            >
              <label>{{ $t('type_id') }}</label>
              <v-select
                v-model='searchFields.type_id'
                :dir='$store.state.appConfig.layout.direction'
                :options='type_id'
                :reduce='item => item.id'
                label='name'
                class='w-100'
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
            </b-col>
            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mb-2'
            >
              <label>{{ $t('labels.status') }}</label>
              <v-select
                v-model='searchFields.status'
                :dir='$store.state.appConfig.layout.direction'
                :options='status_list'
                :reduce='item => item.value'
                label='label'
                class='w-100'
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
            </b-col>

            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mb-2'
            >
              <label>{{ $t('region') }}</label>
              <v-select
                v-model='region_id'
                :dir='$store.state.appConfig.layout.direction'
                :options='regions'
                :reduce='item => item.id'
                label='name'
                class='w-100'
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
            </b-col>
            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mb-2'
            >
              <label>{{ $t('city') }}</label>
              <v-select
                v-model='searchFields.city_id'
                :dir='$store.state.appConfig.layout.direction'
                :options='cities'
                :reduce='item => item.id'
                label='name'
                class='w-100'
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
            </b-col>
            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mb-2'
            >
              <label>{{ $t('district') }}</label>
              <v-select
                v-model='searchFields.district_id'
                :dir='$store.state.appConfig.layout.direction'
                :options='districts'
                :reduce='item => item.id'
                label='name'
                class='w-100'
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
            </b-col>

            <b-col cols='12'
                   md='4'
                   class='mb-md-0 my-1'>
              <NewCustomerSelector @update:customer_id='handleCustomerIdsUpdate' />
            </b-col>
            <b-col
              cols='12'
              md='4'
              class='mb-md-0 mt-1'
            >
              <DatePicker
                :label="$t('labels.created_on_adv')"
                :reset='true'
                v-model='created_at'
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <TableIndex
        :resource-name='resourceName'
        :singular-name='singularName'
        :can-change-status='false'
        :custom-change-status='selectedItemToChangeStatus'
        :have-dropdown-actions='true'
        :can-splice='false'
        :filter-fields='searchFields'
        :RealEstateAdd='true'
        :permissionKey='"real-estate"'

      >
        <template v-slot:dropdown_actions='{ item, generalActions }'>
          <b-dropdown-item
            v-if='(item.actions && item.actions.can_change_status)'
            @click='showModal(item,item.status)'
          >
            <feather-icon
              icon='FilterIcon'
              class='mr-50'
            />
            <span>{{ $t('status') }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if='(item.actions && item.actions.can_view)'
            variant='link'
            :to="{ name: 'show-images-real-estate-not-licensed', params: { resourceId: item.id } }"
          >
            <feather-icon
              icon='ImageIcon'
              class='mr-50'
            />
            <span>{{ $t('attachments') }}</span>
          </b-dropdown-item>
<!--          <b-dropdown-item-->
<!--            v-if='(item.actions && item.actions.can_view)'-->
<!--            variant='link'-->
<!--            :to="{ name: 'real-estate-not-licensed-ratings', params: { resourceId: item.id, rateable_id: item.id } }"-->
<!--          >-->
<!--            <feather-icon-->
<!--              icon='StarIcon'-->
<!--              class='mr-50'-->
<!--            />-->
<!--            <span>{{ $t('ratings') }}</span>-->
<!--          </b-dropdown-item>-->
          <!--        <b-dropdown-item-->
          <!--          v-if='(item.actions && item.actions.can_view)'-->
          <!--          variant='link'-->
          <!--          :to="{ name: 'real-estate-views', params: { resourceId: item.id } }"-->
          <!--        >-->
          <!--          <feather-icon-->
          <!--            icon='EyeIcon'-->
          <!--            class='mr-50'-->
          <!--          />-->
          <!--          <span>{{ $t('views') }}</span>-->
          <!--        </b-dropdown-item>-->
          <!--        <b-dropdown-item-->
          <!--          v-if="(item.actions && item.actions.can_view)"-->
          <!--          variant="link"-->
          <!--          :to="{ name: 'real-estate-call-evaluation', params: { resourceId: item.id } }"-->
          <!--        >-->
          <!--          <feather-icon-->
          <!--            icon="PhoneCallIcon"-->
          <!--            class="mr-50"-->
          <!--          />-->
          <!--          <span>{{ $t('call-evaluation') }}</span>-->
          <!--        </b-dropdown-item>-->
          <b-dropdown-item
            v-if='(item.actions && item.actions.can_view)'
            variant='link'
            :to="{ name: 'details-real-estate-not-licensed', params: { resourceId: item.id } }"
          >
            <feather-icon
              icon='EyeIcon'
              class='mr-50'
            />
            <span>{{ $t('details') }}</span>
          </b-dropdown-item>
        </template>
      </TableIndex>
    </div>
    <b-modal
      id='modal-status'
      ref='modal-status'
      cancel-variant='outline-secondary'
      :ok-title="$t('yes')"
      :cancel-title="$t('no')"
      centered
      :title="$t('change_status')"
      hide-footer
      :cancel-disabled='false'
    >
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-form
          novalidate
          @submit.prevent='changeStatus'
        >
          <validation-provider
            v-slot='{ errors }'
            vid='status'
            :name="$t('status')"
            rules='required'
          >
            <b-form-group
              :label="$t('status')"
            >
              <v-select
                v-model='form.status'
                :dir='$store.state.appConfig.layout.direction'
                label='label'
                :options='status_filter_list'
                :reduce='item => item.value'
                :placeholder="$t('select')"
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
              <!--              <small class='text-danger'>{{ errors[0] }}</small>-->
            </b-form-group>
          </validation-provider>
          <div class='d-flex'>
            <LoadingButton :loader='loader' />
            <b-button
              variant='outline-secondary'
              @click='hideModal'
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { websiteMainUrl } from '@/services/url.service'
import NewCustomerSelector from '@/components/NewCustomerSelector.vue'


export default {
  components: { NewCustomerSelector },
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate',
      singularName: 'real-estate-not-licensed',
      type_id: [],
      regions: [],
      cities: [],
      districts: [],
      loader: false,

      form: {
        status: null,
      },
      status_list: [],
      status_filter_list: [],

      websiteUrl: websiteMainUrl(),
      item_id: null,
      selectedItemToChangeStatus: null,
      created_at: null,
      region_id: null,

      searchFields: {
        title: null,
        type_id: null,
        status: null,
        created_at: null,
        city_id: null,
        district_id: null,
        ad_type: null,
        creator_id: null,
        privacy_type: 0,

      },
    }
  },
  watch: {
    'region_id': {
      handler(val) {
        this.searchFields.city_id = null
        this.searchFields.district_id = null
        this.getCities(val)
      },
    },
    'searchFields.city_id': {
      handler(val) {
        this.searchFields.district_id = null
        this.getDistrict(val)
      },
    },

    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },

  },


  created() {

    this.getRegion()

    this.axios.get(`/model-list/real-estate-status`)
      .then(res => {
        this.status_list = res.data.data

        this.status_filter_list = res.data.data.filter(function(item) {
          return item.value !== 1 && item.value !== 12 && item.value !== 6
        })

      })
    this.getRealEstateTypes()

  },
  methods: {
    handleCustomerIdsUpdate(customerId) {
      if (customerId) {
        this.searchFields.creator_id = customerId
      } else {
        this.searchFields.creator_id = null
      }
    },
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),
    showModal(item, status) {
      status === 'approved' ? this.form.status = null : this.form.status = status

      this.$refs['modal-status'].show()

      if (item.status == 12 || item.status == 6) {
        this.form.status = null
      }

      this.item_id = item.id

    },
    hideModal() {
      this.$refs['modal-status'].hide()
      setTimeout(() => {
        this.item_id = null
        this.form.status = null
      }, 500)
    },
    changeStatus() {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.loader = true
            this.axios.patch(`/real-estate/${this.item_id}/change-status`, this.form)
              .then(response => {
                this.selectedItemToChangeStatus = response.data.data
                this.hideModal()
                this.loader = false

                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                  this.form.status = null
                })
              }).catch(error => {
              this.loader = false
            })
          }
        })
    },
    getRealEstateTypes() {
      this.axios.get(`/model-options/types`)
        .then(res => {
          this.type_id = res.data.data
        })
    },
    getRegion() {
      this.axios.get(`/model-options/region`)
        .then(res => {
          this.regions = res.data.data
        })
    },
    getCities(val = null) {

      if (val === null) {
        this.cities = []
      }

      this.axios.get(`/model-options/city?region_id=${val}`)
        .then(res => {
          this.cities = res.data.data
          return 1
        })
    },
    getDistrict(val = null) {

      if (val === null) {
        this.districts = []
        return 1
      }
      this.axios.get(`/model-options/district?city_id=${val}`)
        .then(res => {
          this.districts = res.data.data
        })
    },
    getSearchWorld(p) {
      const params = {
        search_key: p,
      }
      this.axios.get('/search-world', { params })
        .then(res => {
          this.searchWorld = res.data.data
        })
    },
    onChangeSearchWorld(e) {
      this.searchFields = {
        ...this.searchFields,
        [e.key]: e.value,
      }
    },
  },
}
</script>

<style scoped>
.main-btn-link {
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  flex-direction: column;
}
</style>